import router from "../axios";
import config from "../../config/index"
const { baseUrl } = config;

function getList(data) {
  // 获取价签列表
  return router({
        url: baseUrl+"tag/v1/getList",
        data: data,
        method: "POST",
        header:{
            'Content-Type':'application/json'
        }
  });
}
// 获取价签更新记录
function getRecordList(data) {
    // 获取价签列表
    return router({
        url: baseUrl+"tag/v1/getRecordList",
        data: data,
        method: "POST",
        header:{
            'Content-Type':'application/json'
        }
    });
}
// 获取所有价签类型 
function getAllTagType(data) {
    // 获取价签列表
    return router({
        url: baseUrl+"tag/v1/getAllTagType",
        data: data,
        method: "POST",
        header:{
            'Content-Type':'application/json'
        }
    });
}

// 获取所有价签类型(带分页) 
function getAllTagTypePage(data) {
    // 获取价签列表
    return router({
        url: baseUrl+"tag/v1/getAllTagTypePage",
        data: data,
        method: "POST"
    });
}


function batchDelete(data) {
    //批量逻辑删除价签信息
    return router({
        url: baseUrl+"tag/v1/batchDelete",
        data: data,
        method: "POST"
    });
}

function delete_(data) {
    //逻辑删除价签信息  通过id逻辑删除价签信息 
    return router({
        url: baseUrl+"tag/v1/delete",
        data: data,
        method: "POST"
    });
}
function exportExcel(data) {
    //价签导出Excel  
    return router({
        url: baseUrl+"tag/v1/exportExcel",
        data: data,
        method: "POST",
        responseType: "blob"
    });
}
function pushTag(data) {
    //推送单个价签  根据价签id执行推送任务
    return router({
        url: baseUrl+"push/v1/pushTag",
        data: data,
        method: "POST"
    });
}

function batchPushTag(data) {
    //批量推送价签 
    return router({
        url: baseUrl+"push/v1/batchPushTag",
        data: data,
        method: "POST"
    });
}
function pushTagByCoor(data) {
    //根据基站批量推送价签 
    return router({
        url: baseUrl+"push/v1/pushTagByCoor",
        data: data,
        method: "POST"
    });
}

function pushTagByType(data) {
    //价签类型推送价签任务 
    return router({
        url: baseUrl+"push/v1/pushTagByType",
        data: data,
        method: "POST"
    });
}


function getTagDetail(data) {
    //获取视图信息详情
    return router({
        url: baseUrl+"tag/v1/get",
        data: data,
        method: "POST"
    });
}


// 视图部分
function getViewList(data) {
    // 获取视图选择数据list下拉框
    return router({
        url: baseUrl+"view/v1/getList",
        data: data,
        method: "POST",
    });
}
function createBefore(data) {
    // 创建视图前，获取视图字段
    return router({
        url: baseUrl+"view/v1/createBefore",
        data: data,
        method: "POST",
    });
}

function getViewDetail(data) {
    //获取视图信息详情
    return router({
        url: baseUrl+"view/v1/get",
        data: data,
        method: "POST",
    });
}
function getViewCreate(data) {
    //创建视图
    return router({
        url: baseUrl+"view/v1/create",
        data: data,
        method: "POST",
    });
}
function getViewUpdate(data) {
    //更新视图
    return router({
        url: baseUrl+"view/v1/update",
        data: data,
        method: "POST",
    });
}
function getViewDelete(data) {
    //删除视图
    return router({
        url: baseUrl+"view/v1/delete",
        data: data,
        method: "POST",
    });
}
export {
    getList,
    getRecordList,
    getAllTagType,
    getAllTagTypePage,
    batchDelete,
    getViewList,
    createBefore,
    getViewDetail,
    getViewCreate,
    getViewUpdate,
    getViewDelete,

    getTagDetail,
    pushTag,
    batchPushTag,
    pushTagByCoor,
    pushTagByType,
    delete_,
    exportExcel
}