<template>
  <div class="common-table-list common-table-list-rectcle">
    <div class="location">
      <router-link style="color: #6e6b7b" to="/">{{
        $t("Welcome")
      }} > </router-link>
      <span style="color:#3461FF">{{ $t('recycle') }}</span>
    </div>
    <div class="senlists">
      <div>
        <div class="hd card">
          <div><el-button type="danger" size="small" @click="handleAdd" :disabled="!selections()">
              <img width="14px" height="14px" src="../../assets/img/BatcheRestore.png" />
              <!--  <i class="el-icon-plus" style="font-size: 16px;margin-right: 5px;color: black;"></i> -->
              {{ $t('Recycle.batch Restore') }}
            </el-button>
            <el-button type="danger" size="small" @click="handleBatchDel" :disabled="!selections()">
              <i class="el-icon-delete" />
              {{ $t('Recycle.clear') }}
            </el-button>
            <div class="block">
              <el-cascader v-model="value" :options="options" @change="handleChange" size="small"></el-cascader>
            </div>
          </div>
        </div>
        <div class="bd card">
          <div style="margin-bottom: 15px;display:flex;justify-content: space-between;">
            <div>
              <span class="text">{{ $t('images.Records') }}:{{ pageParams.total }}</span>
              <!-- 所在门店/下级门店/所有门店下拉列表 -->
              <template
                v-if="value && !(value[0] === 'rc_customer' || value[0] === 'rc_whiteList' || value[0] === 'rc_interface' || value[0] === 'rc_scenarios' || value[0] === 'rc_mc_rule_1'|| value[0] === 'rc_mc_rule_0')">
                <el-select v-model="searchParams.storeType" @change="getData" placeholder="This Store" size="small">
                  <el-option :label="$t('ThisStore')" value="2" />
                  <el-option :label="$t('ZimenStore')" value="1" />
                  <el-option :label="$t('AllStore')" value="0" />
                </el-select>
              </template>
            </div>
            <button class="btnreo" @click="btnreos" ref="btn"><i class="el-icon-refresh"></i></button>

          </div>
          <!-- 表格 -->
          <el-table ref="normalTable" :data="dataList" :default-sort="{ prop: 'date', order: 'descending' }"
            style="width: 100%" @sort-change="handleSort" max-height="500">
            <!-- <el-table-column width="50" fixed>
                <el-table-column type="selection" width="50">
                  <template slot="header" slot-scope="scope"></template>
                </el-table-column>
              </el-table-column> -->
            <el-table-column align="center" type="selection" fixed>
              <el-table-column align="center" width="55" type="selection">
              </el-table-column>
            </el-table-column>
            <el-table-column v-if="pkCode" :prop="pkCode" min-width="130" :label="$t(`${Recyclecode}`)"
              :sortable="this.sortablef" header-align="center"><!-- 加sortable排序 -->
              <el-table-column prop="pkCode" min-width="130" align="center">
                <template v-if="pkCode && showPkCodeSearch" slot="header" slot-scope="scope">
                  <el-input size="mini" v-model="searchParams.code" @input="getData" placeholder="" clearable
                    style="width:220px;"></el-input>
                </template>
                <!-- <template slot="default" slot-scope="{row}">
                  <div v-if="pkCode">{{ row.pkCode }}</div>
                  <div v-else>-</div>
                </template> -->
              </el-table-column>
            </el-table-column>
            <el-table-column :prop="pkName" min-width="130" :label="$t(`${Recyclename}`)" :sortable="this.sortableftwo"
              header-align="center"><!-- 加sortable排序 -->
              <el-table-column prop="pkName" min-width="130" align="center">
                <template slot="header" slot-scope="scope">
                  <el-select v-model="valuet" clearable placeholder="请选择" size="small" @change="getDatas" id="listselect">
                    <el-option v-for="item in optionst" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                  <el-input v-if="showPkNameSearch" size="mini" v-model="searchParams.name" @input="getData" placeholder="" clearable
                    style="width:220px;" id="listinput"></el-input>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column :prop="updateTime" min-width="100" :label="$t('Recycle.time')"
              :sortable="this.sortablefthreen" header-align="center"><!-- 加sortable排序 -->
              <el-table-column prop="updateTime" min-width="100" align="center">
                <template slot="header" slot-scope="scope">
                  <!-- <el-input size="small" v-model="searchParams.groupName" @input="getData" placeholder=""></el-input> -->
                  <el-date-picker v-model="searchParams.time" type="daterange" size="mini" @change="handleDateChange"
                    placement="bottom-start" value-format="yyyy-MM-dd" />
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column :prop="updateUser" :label="$t('Recycle.operator')" :sortable="this.sortableffore"
              header-align="center">
              <!-- <el-table-column prop="imageTagsName" label="Tags" sortable="custom">  -->
              <el-table-column prop="updateUser" align="center">
                <template slot="header" slot-scope="scope">
                  <el-input size="mini" v-model="searchParams.operator" @input="getData" placeholder=""
                    clearable></el-input>
                </template>
              </el-table-column>
            </el-table-column>

            <el-table-column fixed="right" :label="$t('images.Action')" width="120" header-align="center">
              <el-table-column align="center" width="120">
                <template slot="header"></template>
                <template slot="default" slot-scope="{row}">
                  <div class="operate">
                    <span class="table-icon" @click="handleEdit(row)">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="17px"
                        height="19px">
                        <image x="0px" y="0px" width="17px" height="19px"
                          xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAATCAMAAAC9bj0JAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABIFBMVEX///8vO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2EvO2H///9GEaW0AAAAXnRSTlMALeRrN+uQ8ULzNUP2ngxO+ij+56dQBFMkUXuRw/vgV1v93BwQbOOsDmLYlswV+DRpxwlc5S5xnEf0P6PAs4SmTPLp2hvdBiXeagFIi+i1aBjPgmDWD7ydF8qpME96agXsCwAAAAFiS0dEAIgFHUgAAAAHdElNRQfmCwsOLSS6r3cbAAAA70lEQVQY021Q51rCQBA8giEogqGLQjAEUUCaFKVjVNBIU4oFnPd/DI+QmMCX/TNzM9/e7g4hWtkYO7GqAxYOS53jLA3nIXtkZbiOWRuB27Ovn/CMlxAf4A8EQyY9zDOnFCJn59EYhPi/fiEmJJ0nL1O4ut7ytJiRTO2eLG5yKsujsDuxiNLtBst8pbrr1HCn4n2da2yw2Wq13Z0y3aOLnuoU2HyYAh4EWQTwWHuSn/uqM3gRX6lBL1ekt6GA0RiT7a/Td3woeiSh2QiYa/MWS3waWX19Aw2N939gCnExR1bnigNDY+NVLGU81uYAfpk/fUAitpfSTe4AAAAASUVORK5CYII=" />
                      </svg>
                      <!--  <i class="el-icon-plus" style="font-size: 16px;margin-right: 5px;color: black;"></i> -->
                    </span>
                    <span class="table-icon" @click="handleDelete(row)">
                      <i class="el-icon-delete" style="font-size: 16px;color: black;"></i>
                    </span>
                  </div>
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
          <!-- 页码 -->
          <pagination :tableInfo="pageParams" @changePage="getData" id="wanpage" />
        </div>

      </div>
    </div>

    <!-- 删除弹窗确认 -->
    <deleteConfirmDialog ref="deleteConfirmDialog" @onDeleteConfirm="recycleDelete"></deleteConfirmDialog>
    <!-- 还原弹框确认 -->
    <reduction ref="reduction" @onDeleteConfirm="recycleDeletes"></reduction>

    <!-- 创建素材 -->
    <!-- <createImg 
      ref="createImg" 
      :editData="editData"
      @getData="getData">
    </createImg> -->
  </div>
</template>

<script>
import moment from "moment";
import pagination from '@/components/pagination'
import deleteConfirmDialog from '@/components/delete-confirm-dialog/index'
import reduction from '@/components/date-recycle-dialog/index.vue'
import { debounce } from 'lodash';
import { getAllTagType } from "@/libs/api/esls"
import { sceneimplificlist, getScenarioSimplifyList, getRecoveredScenarioSimpList, recyclebinlist, delectlist, reductionList, getrecyclebinList } from '@/libs/api/scenario-item'//reductionList 批量还原 delectlist 批量删除 sceneimplificlist 场景列表 recyclebinlist  查询列表 getrecyclebinList 菜单查询
export default {
  name: '',
  components: {
    pagination,
    deleteConfirmDialog,
    reduction,
  },
  data() {
    return {
      num: 1,
      pkCode: "itemCode",
      showPkCodeSearch:true,
      showPkNameSearch:true,
      pkName: "itemName",
      updateTime: "updateTime",
      updateUser: "updateUser",
      Recyclecode: "Recycle.code",
      Recyclename: "Recycle.name",
      sortablef: true,
      sortableftwo: true,
      sortablefthreen: true,
      sortableffore: true,
      value: [],
      values: [],
      valuetts: [],
      dataList: [],
      optionst: [],
      valuet: '',
      sids: "",
      searchParams: {
        code: '',
        name: '',
        time: '',
        times: '',
        operator: "",
        sid: "",
        storeType: "2", //门店类型，0：所有门店，1：子级门店 2：当前门店
      },

      deleteData: "",
      relecyle: "",
      pageParams: {
        current: 1,//当前页
        size: 10,//单页显示最大数
        total: 0,
        pageSizeArr: ['10', '20', '50', '100', '200', '500', '1000'],
      },
      watchjson: localStorage.getItem("recoveryname"),
      senlist: [],
      activeName: 'first0',
      senid: "",
      value: "",
      label: "",
      options: []
    }
  },
  methods: {
    //类型下拉选择框（用户、机构、用户组....）---选择事件
    handleChange(value) {
      console.log(value);
      this.showPkCodeSearch = true
      this.showPkNameSearch = true
      if (value.length == 1) {
        this.label = value[0]
        this.valuetts = "";
      } else if (value.length == 2) {
        this.label = value[0];
        this.valuetts = value[1];
      }
      console.log(value[0]);
      this.sortableftwo = true;
      this.sortablefthreen = true;
      this.sortableffore = true;
      document.querySelector(".is-group").childNodes[1].style = ""
      document.querySelector("#wanpage").style = "";
      if (value[0] == "rc_esl") {//价签或白名单翻译
        this.Recyclecode = 'Recycle.code1';
        this.Recyclename = 'Recycle.name1';
        let ww = {
          merchantId: "",
          source: ""
        }
        this.sortablef = true;
        this.pkCode = "tag_identity";
        this.pkName = "tag_type_id";
        this.updateTime = "update_time";
        this.updateUser = "update_user";

        console.log(document.querySelector(".cell>.el-input.el-input--mini.el-input--suffix #listinput").parentNode);
        document.querySelector(".cell>.el-select.el-select--small").style = "display:block";
        document.querySelector(".cell>.el-input.el-input--mini.el-input--suffix #listinput").parentNode.style = "display:none";
        getAllTagType(ww).then(reser => {
          console.log(reser);
          if (reser.status == 200) {
            if (reser.data.state == true) {
              this.optionst = []
              reser.data.data.forEach((itm, ind) => {
                this.optionst.push({ label: itm.typeName, value: itm.tagTypeCodeId })
              })
            }
          }
        })
      } else if (value[0] == "rc_whiteList") {
        this.Recyclecode = 'Recycle.code1';
        this.Recyclename = 'Recycle.name1';
        this.sortablef = true;
        this.sortableftwo = false;
        this.pkCode = "tag_identity";
        this.pkName = "user_name";
        this.updateTime = "update_time";
        this.updateUser = "update_user";
        document.querySelector(".cell>.el-select.el-select--small").style = "display:none";
        document.querySelector(".cell>.el-input.el-input--mini.el-input--suffix #listinput").parentNode.style = "display:blaock";
      } else if (value[0] == "rc_user") {//用户翻译
        this.Recyclecode = 'Recycle.code2';
        this.Recyclename = 'Recycle.name2';
        this.sortablef = true;
        this.pkCode = "login_id";
        this.pkName = "user_name";
        this.updateTime = "update_time";
        this.updateUser = "update_user";
        document.querySelector(".cell>.el-select.el-select--small").style = "display:none";
        document.querySelector(".cell>.el-input.el-input--mini.el-input--suffix #listinput").parentNode.style = "display:blaock";
      } else if (value[0] == "rc_store") {//门店翻译
        this.Recyclecode = 'Recycle.code3';
        this.Recyclename = 'Recycle.name3';
        this.sortablef = true;
        this.pkCode = "customer_store_code";
        this.pkName = "store_name";
        this.updateTime = "update_time";
        this.updateUser = "update_user";
        document.querySelector(".cell>.el-select.el-select--small").style = "display:none";
        document.querySelector(".cell>.el-input.el-input--mini.el-input--suffix #listinput").parentNode.style = "display:blaock";
      } else if (value[0] == "rc_image") {//图片翻译
        this.Recyclecode = 'Recycle.code4';
        this.Recyclename = 'Recycle.name4';
        this.pkCode = "";
        this.sortablef = false;
        this.pkName = "image_name";
        this.updateTime = "update_time";
        this.updateUser = "update_user";
        document.querySelector(".cell>.el-select.el-select--small").style = "display:none";
        document.querySelector(".cell>.el-input.el-input--mini.el-input--suffix #listinput").parentNode.style = "display:blaock";
      } else if (value[0] == "rc_template") {//模板翻译
        this.Recyclecode = 'Recycle.code5';
        this.Recyclename = 'Recycle.name5';
        this.sortablef = true;
        this.pkCode = "template_code";
        this.pkName = "template_name";
        this.updateTime = "update_time";
        this.updateUser = "update_user";
        document.querySelector(".cell>.el-select.el-select--small").style = "display:none";
        document.querySelector(".cell>.el-input.el-input--mini.el-input--suffix #listinput").parentNode.style = "display:blaock";
      } else if (value[0] == "rc_APs") {//基站翻译
        this.Recyclecode = 'Recycle.code6';
        this.Recyclename = 'Recycle.name6';
        this.sortablef = true;
        this.pkCode = "ip_addr";
        this.pkName = "mac_address";
        this.updateTime = "update_time";
        this.updateUser = "update_user";
        document.querySelector(".cell>.el-select.el-select--small").style = "display:none";
        document.querySelector(".cell>.el-input.el-input--mini.el-input--suffix #listinput").parentNode.style = "display:blaock";
      } else if (value[0] == "rc_item") {//商品翻译
        this.Recyclecode = 'Recycle.code7';
        this.Recyclename = 'Recycle.name7';
        this.sortablef = true;
        document.querySelector(".cell>.el-select.el-select--small").style = "display:none";
        document.querySelector(".cell>.el-input.el-input--mini.el-input--suffix #listinput").parentNode.style = "display:blaock";
      } else if (this.label == "rc_layout") {//布局
        this.Recyclecode = 'Recycle.code8';
        this.Recyclename = 'Recycle.name8';
        document.querySelector("#wanpage").style = "position:absolute;top:-9999px;";
        document.querySelector(".is-group").childNodes[1].style = "display:none;"
        this.sortablef = false;
        this.sortablefthreen = false;
        this.sortableffore = false;
        this.sortableftwo = false;
        this.pkCode = "layout_strategy_name";
        this.pkName = "layout_size";
        this.updateTime = "update_time";
        this.updateUser = "update_user";
        document.querySelector(".cell>.el-select.el-select--small").style = "display:none";
        document.querySelector(".cell>.el-input.el-input--mini.el-input--suffix #listinput").parentNode.style = "display:blaock";
      } else if (this.label == "rc_task") {//任务
        this.Recyclecode = 'Recycle.code9';
        this.Recyclename = 'Recycle.name9';
        this.sortablef = false;
        this.pkCode = "";
        this.pkName = "task_name";
        this.updateTime = "update_time";
        this.updateUser = "update_user";
        document.querySelector(".cell>.el-select.el-select--small").style = "display:none";
        document.querySelector(".cell>.el-input.el-input--mini.el-input--suffix #listinput").parentNode.style = "display:blaock";
      } else if (this.label == "rc_userGroup") {//用户组
        this.Recyclecode = 'Recycle.code10';
        this.Recyclename = 'Recycle.name10';
        this.sortablef = false;
        this.pkCode = "";
        this.pkName = "role_name";
        this.updateTime = "update_time";
        this.updateUser = "update_user";
        document.querySelector(".cell>.el-select.el-select--small").style = "display:none";
        document.querySelector(".cell>.el-input.el-input--mini.el-input--suffix #listinput").parentNode.style = "display:blaock";
      } else if (this.label == "rc_customer") {//客户
        this.Recyclecode = 'Recycle.code11';
        this.Recyclename = 'Recycle.name11';
        document.querySelector("#wanpage").style = "position:absolute;top:-9999px;";
        this.sortablef = false;
        this.sortableftwo = false;
        this.sortablefthreen = false;
        this.sortableffore = false;
        document.querySelector(".cell>.el-select.el-select--small").style = "display:none";
        document.querySelector(".cell>.el-input.el-input--mini.el-input--suffix #listinput").parentNode.style = "display:blaock";
      } else if (this.label == "rc_scenarios") {//场景配置
        this.Recyclecode = 'Recycle.code12';
        this.Recyclename = 'Recycle.name12';
        document.querySelector("#wanpage").style = "position:absolute;top:-9999px;";
        document.querySelector(".is-group").childNodes[1].style = "display:none;"
        console.log(document.querySelector(".is-group").childNodes[1].style = "display:none");
        this.sortablef = false;
        this.sortablefthreen = false;
        this.sortableffore = false;
        this.sortableftwo = false;
        this.pkCode = "defaultTextName";
        this.pkName = "fieldKey";
        this.updateTime = "updateTime";
        this.updateUser = "updateUser";
        document.querySelector(".cell>.el-select.el-select--small").style = "display:none";
        document.querySelector(".cell>.el-input.el-input--mini.el-input--suffix #listinput").parentNode.style = "display:blaock";
      } else if (this.label == "rc_interface") {//接口配置
        this.Recyclecode = 'Recycle.code13';
        this.Recyclename = 'Recycle.name13';
        this.sortablef = false;
        this.pkCode = "";
        this.pkName = "interface_name";
        this.updateTime = "update_time";
        this.updateUser = "update_user";
        document.querySelector(".cell>.el-select.el-select--small").style = "display:none";
        document.querySelector(".cell>.el-input.el-input--mini.el-input--suffix #listinput").parentNode.style = "display:block";
      } else if (this.label == "rc_unit") {//计量单位转换
        this.Recyclecode = 'Recycle.code14';
        this.Recyclename = 'Recycle.name14';
        this.sortablef = false;
        this.pkCode = "pkCode";
        this.pkName = "pkName";
        this.updateTime = "update_time";
        this.updateUser = "update_user";
        document.querySelector(".cell>.el-select.el-select--small").style = "display:none";
        document.querySelector(".cell>.el-input.el-input--mini.el-input--suffix #listinput").parentNode.style = "display:block";
      } else if (this.label == "rc_mc_rule_1") { //商品查询配置
        this.Recyclecode = 'Recycle.code15';
        this.Recyclename = 'Recycle.name15';
        // document.querySelector("#wanpage").style = "position:absolute;top:-9999px;";
        // document.querySelector(".is-group").childNodes[1].style = "display:none;"
        this.sortablef = false;
        this.sortableftwo = false;
        this.pkCode = "pkCode";
        this.pkName = "pkName";
        this.showPkCodeSearch = false
        this.showPkNameSearch = false
        this.updateTime = "update_time";
        this.updateUser = "update_user";
      } else if (this.label == "rc_mc_rule_0") { //条码截取配置
        this.Recyclecode = 'Recycle.code15';
        this.Recyclename = 'systemParameters.RuleDetail';
        this.sortablef = false;
        this.sortableftwo = false;
        this.pkCode = "pkCode";
        this.pkName = "pkName";
        this.showPkCodeSearch = false
        this.showPkNameSearch = false
        this.updateTime = "update_time";
        this.updateUser = "update_user";
      }
      let data = {
        storeId: localStorage.getItem("storeId"),
        storeType: this.searchParams.storeType,//门店类型，0：所有门店，1：子级门店 2：当前门店
        scenarioId: this.valuetts,
        reCycleType: value[0],
        current: "1",
        size: "10",
        pkName: this.searchParams.name,//名称
        pkCode: this.searchParams.code,//code
        updateTime: this.searchParams.times,//时间
        updateUser: this.searchParams.operator,//删除人
        orders: this.values,
      }
      this.dataList = [];
      recyclebinlist(data).then(res => {
        if (res.status == 200) {
          if (res.data.state == true) {
            this.pageParams.current = res.data.data.current;
            this.pageParams.size = res.data.data.size;
            this.pageParams.total = res.data.data.total;
            res.data.data.records.forEach((item) => {//当pkCode为空时用“--”代替
              if (item.pkName == "" || item.pkName == undefined || item.pkName == null) {//20  28
                this.dataList.push({
                  sid: item.sid,
                  pkId: item.pkId,
                  pkCode: item.pkCode,
                  pkName: "--",
                  updateTime: item.updateTime,
                  updateUser: item.updateUser
                })
              } else {
                this.dataList.push({
                  sid: item.sid,
                  pkId: item.pkId,
                  pkCode: item.pkCode,
                  pkName: item.pkName,
                  updateTime: item.updateTime,
                  updateUser: item.updateUser
                })
              }
            })
          }
        }
      })
    },
    btnreos() {//刷新
      console.log();
      document.querySelector(".btnreo").disabled = "true";
      let selelist = {//searchParams.name  searchParams.code  searchParams.time searchParams.operator
        storeType: this.searchParams.storeType,//门店类型，0：所有门店，1：子级门店 2：当前门店
        pkName: this.searchParams.name,//名称
        pkCode: this.searchParams.code,//code
        updateTime: this.searchParams.times,//时间
        updateUser: this.searchParams.operator,//删除人
        storeId: localStorage.getItem("storeId"),//门店id
        scenarioId: this.valuetts,//场景id
        reCycleType: this.label,//对象类别
        current: "1",
        size: "10",
        orders: this.values,
      }
      setTimeout(() => {
        this.$refs.btn.removeAttribute('disabled');
      }, 1000)
      recyclebinlist(selelist).then(res => {
        this.dataList = [];
        if (res.status == 200) {
          this.pageParams.current = res.data.data.current;
          this.pageParams.size = res.data.data.size;
          this.pageParams.total = res.data.data.total;
          res.data.data.records.forEach((item) => {//当pkCode为空时用“--”代替
            if (item.pkName == "" || item.pkName == undefined || item.pkName == null) {
              this.dataList.push({
                sid: item.sid,
                pkId: item.pkId,
                pkCode: item.pkCode,
                pkName: "--",
                updateTime: item.updateTime,
                updateUser: item.updateUser
              })
            } else {
              this.dataList.push({
                sid: item.sid,
                pkId: item.pkId,
                pkCode: item.pkCode,
                pkName: item.pkName,
                updateTime: item.updateTime,
                updateUser: item.updateUser
              })
            }
          })
          //this.dataList=res.data.records;111111111111111111
        }
      })
    },
    //tab切换点击事件
    handleClick(tab, event) {
      this.senid = tab.$el.id;
      let data = {
        storeId: localStorage.getItem("storeId"),
        storeType: this.searchParams.storeType,//门店类型，0：所有门店，1：子级门店 2：当前门店
        scenarioId: tab.$el.id,
        reCycleType: "rc_item:",
        current: "1",
        size: "10",
        pkName: this.searchParams.name,//名称
        pkCode: this.searchParams.code,//code
        updateTime: this.searchParams.times,//时间
        updateUser: this.searchParams.operator,//删除人
        orders: this.values,
      }
      recyclebinlist(data).then(res => {//tab切换后获取列表
        if (res.status == 200) {
          this.pageParams.current = res.data.current;
          this.pageParams.size = res.data.size;
          this.pageParams.total = res.data.total;
          res.data.records.forEach((item) => {//当pkCode为空时用“--”代替
            if (item.pkCode == "" || pkCode == undefined || pkCode == null) {
              this.dataList.push({
                sid: item.sid,
                pkId: item.pkId,
                pkCode: "--",
                updateTime: item.updateTime,
                updateUser: item.updateUser
              })
            } else {
              this.dataList.push({
                sid: item.sid,
                pkId: item.pkId,
                pkCode: item.pkCode,
                updateTime: item.updateTime,
                updateUser: item.updateUser
              })
            }
          })
          //this.dataList=res.data.records;;
        }
      })
    },
    //批量删除弹框
    async recycleDelete() {
      let { storeId } = this.deleteData
      let ddd;
      console.log(this.deleteData);
      if (localStorage.getItem("recoveryname") == "apps-recycle0") {
        ddd = "re_item"
        //this.senid="";
      } else if (localStorage.getItem("recoveryname") == "apps-recycle1") {
        ddd = "rc_esl";
        this.senid = "";
      } else if (localStorage.getItem("recoveryname") == "apps-recycle2") {
        ddd = "rc_APs"
        this.senid = "";
      } else if (localStorage.getItem("recoveryname") == "apps-recycle3") {
        ddd = "rc_esl";
        this.senid = "";
      } else if (localStorage.getItem("recoveryname") == "apps-recycle4") {
        ddd = "rc_APs"
        this.senid = "";
      } else if (localStorage.getItem("recoveryname") == "apps-recycle5") {
        ddd = "rc_esl";
        this.senid = "";
      } else if (localStorage.getItem("recoveryname") == "apps-recycle6") {
        ddd = "rc_APs"
        this.senid = "";
      }
      let params = {}
      if (this.label == "rc_scenarios" || this.label == "rc_layout") {
        params = {
          scenarioId: this.valuetts,
          sids: this.deleteData.sid,
          reCycleType: this.label,
        }
      } else {
        params = {
          scenarioId: this.valuetts,
          ids: this.deleteData.imageIds,
          reCycleType: this.label,
        }
      }
      const res = await delectlist(params)
      let response = res.data
      if (response.state) {
        this.$message({
          message: this.$t('commonMessage.DeleteSucess'),
          type: 'success',
        });
        this.$refs.deleteConfirmDialog.close()
        let selelist = {//searchParams.name  searchParams.code  searchParams.time searchParams.operator
          storeType: this.searchParams.storeType,//门店类型，0：所有门店，1：子级门店 2：当前门店
          pkName: this.searchParams.name,//名称
          pkCode: this.searchParams.code,//code
          updateTime: this.searchParams.times,//时间
          updateUser: this.searchParams.operator,//删除人
          storeId: localStorage.getItem("storeId"),//门店id
          scenarioId: this.valuetts,//场景id
          reCycleType: this.label,//对象类别
          current: "1",
          size: "10",
          orders: this.values,
        }
        this.dataList = [];
        recyclebinlist(selelist).then(res => {
          if (res.status == 200) {
            this.pageParams.current = res.data.data.current;
            this.pageParams.size = res.data.data.size;
            this.pageParams.total = res.data.data.total;
            res.data.data.records.forEach((item) => {//当pkCode为空时用“--”代替
              if (item.pkName == "" || item.pkName == undefined || item.pkName == null) {
                this.dataList.push({
                  sid: item.sid,
                  pkId: item.pkId,
                  pkCode: item.pkCode,
                  pkName: "--",
                  updateTime: item.updateTime,
                  updateUser: item.updateUser
                })
              } else {
                this.dataList.push({
                  sid: item.sid,
                  pkId: item.pkId,
                  pkCode: item.pkCode,
                  pkName: item.pkName,
                  updateTime: item.updateTime,
                  updateUser: item.updateUser
                })
              }
            })
            //this.dataList=res.data.records;111111111111111111
          }
        })
      } else {
        this.$refs.deleteConfirmDialog.close()

        this.$message({
          message: response.message,
          type: 'warning',
        });
      }
    },
    //批量还原
    async recycleDeletes() {
      let { storeId } = this.deleteData;
      let ddd;
      if (localStorage.getItem("recoveryname") == "apps-recycle0") {
        ddd = "re_item"
        //this.senid="";
      } else if (localStorage.getItem("recoveryname") == "apps-recycle1") {
        ddd = "rc_esl";
        this.senid = "";
      } else if (localStorage.getItem("recoveryname") == "apps-recycle2") {
        ddd = "rc_APs"
        this.senid = "";
      }
      let params = {};
      console.log(this.label);
      console.log(this.deleteData);
      if (this.label == "rc_scenarios" || this.label == "rc_layout") {
        params = {
          scenarioId: this.valuetts,
          sids: this.deleteData.sid,
          reCycleType: this.label,
        }
      } else {
        params = {
          scenarioId: this.valuetts,
          ids: this.deleteData.imageIds,
          reCycleType: this.label,
        }
      }
      const res = await reductionList(params)
      let response = res.data
      if (response.state) {
        this.$message({
          message: this.$t('commonMessage.ReductionSucess'),
          type: 'success',
        });
        this.$refs.reduction.close()
        let selelist = {//searchParams.name  searchParams.code  searchParams.time searchParams.operator
          storeType: this.searchParams.storeType,//门店类型，0：所有门店，1：子级门店 2：当前门店
          pkName: this.searchParams.name,//名称
          pkCode: this.searchParams.code,//code
          updateTime: this.searchParams.times,//时间
          updateUser: this.searchParams.operator,//删除人
          storeId: localStorage.getItem("storeId"),//门店id
          scenarioId: this.valuetts,//场景id
          reCycleType: this.label,//对象类别
          current: "1",
          size: "10",
          orders: this.values,
        }
        this.dataList = [];
        recyclebinlist(selelist).then(res => {
          if (res.status == 200) {
            this.pageParams.current = res.data.data.current;
            this.pageParams.size = res.data.data.size;
            this.pageParams.total = res.data.data.total;
            res.data.data.records.forEach((item) => {//当pkCode为空时用“--”代替
              if (item.pkName == "" || item.pkName == undefined || item.pkName == null) {
                this.dataList.push({
                  sid: item.sid,
                  pkId: item.pkId,
                  pkCode: item.pkCode,
                  pkName: "--",
                  updateTime: item.updateTime,
                  updateUser: item.updateUser
                })
              } else {
                this.dataList.push({
                  sid: item.sid,
                  pkId: item.pkId,
                  pkCode: item.pkCode,
                  pkName: item.pkName,
                  updateTime: item.updateTime,
                  updateUser: item.updateUser
                })
              }
            })
            //this.dataList=res.data.records;111111111111111111
          }
        })
      } else {
        this.$refs.reduction.close()

        this.$message({
          message: response.message,
          type: 'warning',
        });
      }
    },
    //列表选中
    selections() {
      console.log(this.$refs.normalTable);
      this.watchjson = 2;
      return this.$refs.normalTable && this.$refs.normalTable.selection.length
    },
    //单个还原（恢复）
    handleEdit(row) {
      console.log(row);
      let deleteData = {}
      if (this.valuetts == "rc_scenarios" || this.valuetts == "rc_layout") {
        deleteData = {
          ...row,
          imageIds: row.sid
        }
      } else {
        deleteData = {
          ...row,
          imageIds: row.pkId
        }
      }
      console.log(deleteData);
      this.deleteData = deleteData;
      // this.dialogVisible =true
      this.$refs.reduction.open()
    },
    //批量还原（恢复）
    handleAdd() {
      const selection = this.$refs.normalTable.selection
      let aselct = "";
      let aselcts = "";
      console.log(selection);
      selection.forEach((item, index) => {

        if (this.label == "rc_scenarios" || this.label == "rc_layout") {
          if (index == 0) {
            aselct = item.sid;
          } else {
            aselct = aselct + ',' + item.sid;
          }
        } else {
          if (index == 0) {
            aselcts = item.pkId;
          } else {
            aselcts = aselcts + ',' + item.pkId;
          }
        }

      });
      let imageIdsArr = selection.map(it => it.a)
      let deleteData = {
        sid: aselct,
        imageIds: aselcts
      }
      console.log(deleteData);
      this.deleteData = deleteData
      this.$refs.reduction.open()
    },
    //批量清除（物理删除）
    handleBatchDel() {
      const selection = this.$refs.normalTable.selection
      let aselct = "";
      let aselcts = "";
      console.log(aselcts);
      console.log(selection);
      selection.forEach((item, index) => {
        if (this.label == "rc_scenarios" || this.label == "rc_layout") {
          if (index == 0) {
            aselct = item.sid;
          } else {
            aselct = aselct + ',' + item.sid;
          }
          if (index == 0) {
            aselcts = item.pkId;
          } else {
            aselcts = aselcts + ',' + item.pkId;
          }
        } else {
          if (index == 0) {
            aselct = item.sid;
          } else {
            aselct = aselct + ',' + item.sid;
          }
          if (index == 0) {
            aselcts = item.pkId;
          } else {
            aselcts = aselcts + ',' + item.pkId;
          }
        }

      });
      console.log(aselcts);
      let imageIdsArr = selection.map(it => it.a)
      let deleteData = {
        sid: aselct,
        imageIds: aselcts
      }
      this.deleteData = deleteData
      this.$refs.deleteConfirmDialog.open()
    },
    //单个删除
    handleDelete(row) {
      let deleteData = {}
      console.log(row);
      if (this.valuetts == "rc_scenarios" || this.valuetts == "rc_layout") {
        deleteData = {
          ...row,
          imageIds: row.sid
        }
      } else {
        deleteData = {
          ...row,
          imageIds: row.pkId
        }
      }
      console.log(deleteData);
      this.deleteData = deleteData;
      // this.dialogVisible =true
      this.$refs.deleteConfirmDialog.open()
    },
    handleDateChange() {//搜索框时间选择事件选择删除时间触发
      let ddd;
      if (this.searchParams.time == null || this.searchParams.time == undefined || this.searchParams.time == "") {
        this.searchParams.times = "";
      } else {
        this.searchParams.time.forEach((item, index) => {
          if (index == 0) {
            this.searchParams.times = this.searchParams.time[index]
          } else {
            this.searchParams.times = this.searchParams.times + ',' + this.searchParams.time[index]
          }
        })
      }
      console.log(111111111111111111111111);
      if (localStorage.getItem("recoveryname") == "apps-recycle0") {
        ddd = "re_item"
        //this.senid="";
      } else if (localStorage.getItem("recoveryname") == "apps-recycle1") {
        ddd = "rc_esl";
        this.senid = "";
      } else if (localStorage.getItem("recoveryname") == "apps-recycle2") {
        ddd = "rc_APs"
        this.senid = "";
      } else if (localStorage.getItem("recoveryname") == "apps-recycle") {
        this.senid = "";
      }
      let selelist = {//searchParams.name  searchParams.code  searchParams.time searchParams.operator
        storeType: this.searchParams.storeType,//门店类型，0：所有门店，1：子级门店 2：当前门店
        pkId: this.searchParams.code,//名称
        pkCode: this.searchParams.name,//code
        updateTime: this.searchParams.times,//时间
        updateUser: this.searchParams.operator,//删除人
        storeId: localStorage.getItem("storeId"),//门店id
        scenarioId: this.valuetts,//场景id
        reCycleType: this.label,//对象类别
        current: "1",
        size: "10",
        orders: this.values,
      }
      this.dataList = []
      recyclebinlist(selelist).then(res => {
        if (res.status == 200) {
          this.pageParams.current = res.data.data.current;
          this.pageParams.size = res.data.data.size;
          this.pageParams.total = res.data.data.total;
          res.data.data.records.forEach((item) => {//当pkCode为空时用“--”代替
            if (item.pkName == "" || item.pkName == undefined || item.pkName == null) {
              this.dataList.push({
                sid: item.sid,
                pkId: item.pkId,
                pkCode: item.pkCode,
                pkName: "--",
                updateTime: item.updateTime,
                updateUser: item.updateUser
              })
            } else {
              this.dataList.push({
                sid: item.sid, pkId:
                  item.pkId,
                pkCode: item.pkCode,
                pkName: item.pkName,
                updateTime: item.updateTime,
                updateUser: item.updateUser
              })
            }
          })
          //this.dataList=res.data.records;
        }
      })
    },
    handleSort(column) {
      let arr = []
      // this.searchParams.orders = column.prop + '_' + column.order;
      if (column.order) {
        let str1 = column.order === "ascending" ? "asc" : "desc";
        str1 = str1 + ':' + column.prop;
        arr.push(str1)
      } else {
        arr = []
      }
      this.values = arr;
      //排序
      let data = {
        storeType: this.searchParams.storeType,//门店类型，0：所有门店，1：子级门店 2：当前门店
        pkName: this.searchParams.name,//名称
        pkCode: this.searchParams.code,//code
        updateTime: this.searchParams.times,//时间
        updateUser: this.searchParams.operator,//删除人
        storeId: localStorage.getItem("storeId"),//门店id
        scenarioId: this.valuetts,//场景id
        reCycleType: this.label,//对象类别
        current: "1",
        size: "10",
        orders: arr
      }
      recyclebinlist(data).then(res => {
        console.log(res);
        if (res.status == 200) {
          if (res.data.state == true) {
            this.pageParams.current = res.data.data.current;
            this.pageParams.size = res.data.data.size;
            this.pageParams.total = res.data.data.total;
            res.data.records.forEach((item) => {//当pkCode为空时用“--”代替
              if (item.pkCode == "" || pkCode == undefined || pkCode == null) {
                this.dataList.push({
                  sid: item.sid,
                  pkId: item.pkId,
                  pkCode: "--",
                  updateTime: item.updateTime,
                  updateUser: item.updateUser
                })
              } else {
                this.dataList.push({
                  sid: item.sid,
                  pkId: item.pkId,
                  pkCode: item.pkCode,
                  updateTime: item.updateTime,
                  updateUser: item.updateUser
                })
              }
            })
          } else {
            this.$message({
              message: response.message,
              type: 'warning',
            });
          }

          //this.dataList=res.data.records;;
        }
      })
      //this.searchParams.orders = arr;
    },
    getDatas(val) {
      console.log(val);
      // this.label = val;
      this.searchParams.name = val
      console.log(10011);
      console.log(this.optionst);
      let selelist = {//searchParams.name  searchParams.code  searchParams.time searchParams.operator
        storeType: this.searchParams.storeType,//门店类型，0：所有门店，1：子级门店 2：当前门店
        pkName: val,//名称
        pkCode: this.searchParams.code,//code
        updateTime: this.searchParams.times,//时间
        updateUser: this.searchParams.operator,//删除人
        storeId: localStorage.getItem("storeId"),//门店id
        scenarioId: this.valuetts,//场景id
        reCycleType: this.label,//对象类别
        current: "1",
        size: "10",
        orders: this.values,
      }
      this.dataList = []
      recyclebinlist(selelist).then(res => {
        if (res.status == 200) {
          this.pageParams.current = res.data.data.current;
          this.pageParams.size = res.data.data.size;
          this.pageParams.total = res.data.data.total;
          res.data.data.records.forEach((item) => {//当pkCode为空时用“--”代替
            if (item.pkName == "" || item.pkName == undefined || item.pkName == null) {
              this.dataList.push({
                sid: item.sid,
                pkId: item.pkId,
                pkCode: item.pkCode,
                pkName: "--",
                updateTime: item.updateTime,
                updateUser: item.updateUser
              })
            } else {
              this.dataList.push({
                sid: item.sid,
                pkId: item.pkId,
                pkCode: item.pkCode,
                pkName: item.pkName,
                updateTime: item.updateTime,
                updateUser: item.updateUser
              })
            }
          })
          //this.dataList=res.data.records;111111111111111111
        }
      })
    },
    getData: debounce(async function () {//输入框输入的事件包含编码/名称/操作人
      let { bindState, itemCode, itemName, storeName, orders } = this.searchParams
      let { current, size } = this.pageParams
      let ddd;
      console.log(current, size);
      if (localStorage.getItem("recoveryname") == "apps-recycle0") {
        ddd = "re_item"
        //this.senid="";
      } else if (localStorage.getItem("recoveryname") == "apps-recycle1") {
        ddd = "rc_esl";
        this.senid = "";
      } else if (localStorage.getItem("recoveryname") == "apps-recycle2") {
        ddd = "rc_APs"
        this.senid = "";
      } else if (localStorage.getItem("recoveryname") == "apps-recycle") {
        this.senid = "";
      }

      let selelist = {//searchParams.name  searchParams.code  searchParams.time searchParams.operator
        storeType: this.searchParams.storeType,//门店类型，0：所有门店，1：子级门店 2：当前门店
        pkName: this.searchParams.name,//名称
        pkCode: this.searchParams.code,//code
        updateTime: this.searchParams.times,//时间
        updateUser: this.searchParams.operator,//删除人
        storeId: localStorage.getItem("storeId"),//门店id
        scenarioId: this.valuetts,//场景id
        reCycleType: this.label,//对象类别
        current: current,
        size: size,
        orders: this.values,
      }
      this.dataList = []
      await recyclebinlist(selelist).then(res => {
        if (res.status == 200) {
          this.pageParams.current = res.data.data.current;
          this.pageParams.size = res.data.data.size;
          this.pageParams.total = res.data.data.total;
          res.data.data.records.forEach((item) => {//当pkCode为空时用“--”代替
            if (item.pkName == "" || item.pkName == undefined || item.pkName == null) {
              // moment(val[1]).format("YYYY-MM-DD HH:mm:ss");
              // this.dataList.push({sid:item.sid,pkId:item.pkId,pkCode:item.pkCode,pkName:"--",updateTime:item.updateTime.replace('T'," ").replace('.000+0000',"").slice(0,19),updateUser:item.updateUser})
              this.dataList.push({
                sid: item.sid,
                pkId: item.pkId,
                pkCode: item.pkCode,
                pkName: "--",
                updateTime: item.updateTime.replace('T', " ").replace('.000+0000', "").slice(0, 19),
                updateUser: item.updateUser
              })
            } else {
              this.dataList.push({
                sid: item.sid,
                pkId: item.pkId,
                pkCode: item.pkCode,
                pkName: item.pkName,
                updateTime: item.updateTime,
                updateUser: item.updateUser
              })
            }
          })
          //this.dataList=res.data.records;111111111111111111
        }
      })
    }
    )
  },
  created() {
    getrecyclebinList({ storeId: localStorage.getItem("storeId") }).then((res) => {
      if (res.status == 200) {
        if (res.data.state == true) {
          var newArr = [];
          this.label = Object.keys(res.data.data[0])[0];
          res.data.data.forEach((item, index) => {
            console.log(Object.keys(item)[0]);
            if (Object.keys(item)[0] == "rc_item") {
              let jsondata = {
                scenarioKey: "",
                isEffect: 0,
                agentId: "",
              }
              getScenarioSimplifyList(jsondata).then((redate) => {
                if (redate.status == 200) {
                  if (redate.data.state == true) {
                    console.log(redate);
                    let rr = [];
                    redate.data.data.forEach((it, ind) => {
                      rr.push({
                        value: it.scenarioId,
                        label: it.defaultTextName
                      })
                    })
                    if (redate.data.data.length <= 0) {
                      newArr.splice(5, 0, {
                        value: Object.keys(item)[0],
                        label: item.rc_item,
                      })
                    } else {
                      newArr.splice(5, 0, {
                        value: Object.keys(item)[0],
                        label: item.rc_item,
                        children: rr
                      })
                    }
                  } else {
                    newArr.splice(5, 0, {
                      value: Object.keys(item)[0],
                      label: item.rc_item,
                      //children:[]
                    })
                  }
                } else {
                  newArr.splice(5, 0, {
                    value: Object.keys(item)[0],
                    label: item.rc_item,
                    //children:[]
                  })
                }
              })
            }
            if (Object.keys(item)[0] == "rc_scenarios") {
              let jsondata = {
                merchantId: localStorage.getItem("merchantId"),
                textKey: "",
                scenarioKey: "",
                isEffect: 1,
                agentId: ""
              }

              getRecoveredScenarioSimpList(jsondata).then(ress => {
                console.log(ress);
                if (ress.status == 200) {
                  if (ress.data.state == true) {
                    console.log(ress);
                    let rr = [];
                    ress.data.data.forEach((it, ind) => {
                      rr.push({
                        value: it.scenarioId,
                        label: it.translateTextName
                      })
                    })
                    if (ress.data.data.length <= 0) {
                      newArr.splice(11, 0, {
                        value: Object.keys(item)[0],
                        label: item.rc_scenarios,
                      })
                    } else {
                      newArr.splice(11, 0, {
                        value: Object.keys(item)[0],
                        label: item.rc_scenarios,
                        children: rr
                      })
                    }
                  } else {
                    newArr.splice(11, 0, {
                      value: Object.keys(item)[0],
                      label: item.rc_scenarios,
                      //children:[]
                    })
                  }
                }
              })
            } else {
              if (Object.keys(item)[0] == "rc_item") {

              } else {
                newArr.push({
                  value: Object.keys(item)[0],
                  label: item.rc_esl || item.rc_APs || item.rc_user || item.rc_store || item.rc_image || item.rc_template || item.rc_scenarios || item.rc_task || item.rc_customer || item.rc_userGroup || item.rc_layout || item.rc_whiteList || item.rc_interface || item.rc_unit || item.rc_mc_rule_1 || item.rc_mc_rule_0
                })
              }

            }
          })
          console.log(newArr);
          this.options = newArr;
        }
      }

    })
    if (localStorage.getItem("recoveryname") == "apps-recycle") {//初始化
      let jsondata = {
        scenarioKey: "",
        isEffect: 0,
        agentId: "",
      }
      getScenarioSimplifyList(jsondata).then(res => {//商品下获取场景
        if (res.status == 200) {
          if (res.data.state == true) {
            console.log(res.data.data);
            this.senlist = res.data.data;
            this.senid = res.data.data[0].scenarioId
            //res.data.data[0].scenarioId
            let ordata = {
              storeId: localStorage.getItem("storeId"),
              storeType: this.searchParams.storeType,//门店类型，0：所有门店，1：子级门店 2：当前门店
              scenarioId: this.valuetts,
              reCycleType: this.label,
              current: "1",
              size: "10",
              pkId: this.searchParams.name,//名称
              pkCode: this.searchParams.code,//code
              updateTime: this.searchParams.times,//时间
              updateUser: this.searchParams.operator,//删除人  asc:template_code升序  desc:template_code降序
              orders: this.values,
            }
            recyclebinlist(ordata).then(resd => {//场景下获取列表
              if (resd.status == 200) {
                this.pageParams.current = resd.data.current;
                this.pageParams.size = resd.data.size;
                this.pageParams.total = resd.data.total;
                if (this.label == "rc_esl") {//价签
                  this.Recyclecode = 'Recycle.code1';
                  this.Recyclename = 'Recycle.name1';
                  let ww = {
                    merchantId: "",
                    source: ""
                  }
                  document.querySelector(".cell>.el-select.el-select--small").style = "display:block";
                  document.querySelector(".cell>.el-input.el-input--mini.el-input--suffix #listinput").parentNode.style = "display:none";
                  getAllTagType(ww).then(reser => {
                    console.log(reser);
                  })
                } else if (this.label == "rc_whiteList") {//白名单翻译
                  this.Recyclecode = 'Recycle.code1';
                  this.Recyclename = 'Recycle.name1';
                  document.querySelector(".cell>.el-select.el-select--small").style = "display:none";
                  document.querySelector(".cell>.el-input.el-input--mini.el-input--suffix #listinput").parentNode.style = "display:blaock";
                } else if (this.label == "rc_user") {//用户翻译
                  this.Recyclecode = 'Recycle.code2';
                  this.Recyclename = 'Recycle.name2';
                  document.querySelector(".cell>.el-select.el-select--small").style = "display:none";
                  document.querySelector(".cell>.el-input.el-input--mini.el-input--suffix #listinput").parentNode.style = "display:blaock";
                } else if (this.label == "rc_store") {//门店翻译
                  this.Recyclecode = 'Recycle.code3';
                  this.Recyclename = 'Recycle.name3';
                  document.querySelector(".cell>.el-select.el-select--small").style = "display:none";
                  document.querySelector(".cell>.el-input.el-input--mini.el-input--suffix #listinput").parentNode.style = "display:blaock";
                } else if (this.label == "rc_image") {//图片翻译
                  this.Recyclecode = 'Recycle.code4';
                  this.Recyclename = 'Recycle.name4';
                  document.querySelector(".cell>.el-select.el-select--small").style = "display:none";
                  document.querySelector(".cell>.el-input.el-input--mini.el-input--suffix #listinput").parentNode.style = "display:blaock";
                } else if (this.label == "rc_template") {//模板翻译
                  this.Recyclecode = 'Recycle.code5';
                  this.Recyclename = 'Recycle.name5';
                  document.querySelector(".cell>.el-select.el-select--small").style = "display:none";
                  document.querySelector(".cell>.el-input.el-input--mini.el-input--suffix #listinput").parentNode.style = "display:blaock";
                } else if (this.label == "rc_APs") {//基站翻译
                  this.Recyclecode = 'Recycle.code6';
                  this.Recyclename = 'Recycle.name6';
                  document.querySelector(".cell>.el-select.el-select--small").style = "display:none";
                  document.querySelector(".cell>.el-input.el-input--mini.el-input--suffix #listinput").parentNode.style = "display:blaock";
                } else if (this.label == "rc_item") {//商品翻译
                  this.Recyclecode = 'Recycle.code7';
                  this.Recyclename = 'Recycle.name7';
                  document.querySelector(".cell>.el-select.el-select--small").style = "display:none";
                  document.querySelector(".cell>.el-input.el-input--mini.el-input--suffix #listinput").parentNode.style = "display:blaock";
                } else if (this.label == "rc_layout") {//布局
                  this.Recyclecode = 'Recycle.code8';
                  this.Recyclename = 'Recycle.name8';
                  document.querySelector(".cell>.el-select.el-select--small").style = "display:none";
                  document.querySelector(".cell>.el-input.el-input--mini.el-input--suffix #listinput").parentNode.style = "display:blaock";
                } else if (this.label == "rc_task") {//任务
                  this.Recyclecode = 'Recycle.code9';
                  this.Recyclename = 'Recycle.name9';
                  document.querySelector(".cell>.el-select.el-select--small").style = "display:none";
                  document.querySelector(".cell>.el-input.el-input--mini.el-input--suffix #listinput").parentNode.style = "display:blaock";
                } else if (this.label == "rc_userGroup") {//用户组
                  this.Recyclecode = 'Recycle.code10';
                  this.Recyclename = 'Recycle.name10';
                  document.querySelector(".cell>.el-select.el-select--small").style = "display:none";
                  document.querySelector(".cell>.el-input.el-input--mini.el-input--suffix #listinput").parentNode.style = "display:blaock";
                } else if (this.label == "rc_customer") {//客户
                  this.Recyclecode = 'Recycle.code11';
                  this.Recyclename = 'Recycle.name11';
                  document.querySelector(".cell>.el-select.el-select--small").style = "display:none";
                  document.querySelector(".cell>.el-input.el-input--mini.el-input--suffix #listinput").parentNode.style = "display:blaock";
                } else if (this.label == "rc_scenarios") {//场景配置
                  this.Recyclecode = 'Recycle.code12';
                  this.Recyclename = 'Recycle.name12';
                  document.querySelector(".cell>.el-select.el-select--small").style = "display:none";
                  document.querySelector(".cell>.el-input.el-input--mini.el-input--suffix #listinput").parentNode.style = "display:blaock";
                } else if (this.label == "rc_interface") {//接口配置
                  this.Recyclecode = 'Recycle.code13';
                  this.Recyclename = 'Recycle.name13';
                  document.querySelector(".cell>.el-select.el-select--small").style = "display:none";
                  document.querySelector(".cell>.el-input.el-input--mini.el-input--suffix #listinput").parentNode.style = "display:blaock";
                }
                console.log(resd.data);
                console.log('this.Recyclecode ,this.Recyclename', this.Recyclecode, this.Recyclename)
                if (!resd.data.records) {
                  return false
                }
                resd.data.records.forEach((item) => {//当pkCode为空时用“--”代替
                  if (item.pkName == "" || pkName == undefined || pkName == null) {
                    this.dataList.push({
                      sid: item.sid,
                      pkId: item.pkId,
                      pkCode: item.pkCode,
                      pkName: "--",
                      updateTime: item.updateTime,
                      updateUser: item.updateUser
                    })
                  } else {
                    this.dataList.push({
                      sid: item.sid,
                      pkId: item.pkId,
                      pkCode: item.pkCode,
                      pkName: item.pkName,
                      updateTime: item.updateTime,
                      updateUser: item.updateUser
                    })
                  }
                })
                //this.dataList=resd.data.records;
              }
            })
          } else {
          }
        }
      });
    } else {

    }
  },
}
</script>
<style>
.el-cascader-menu__wrap.el-scrollbar__wrap {
  height: 510px;
}
</style>
<style lang="scss" scoped>
.btnreo {
  background: #35B871;
  border: none;
  padding: 6px 8px;
  border-radius: 4px;
  color: white;
}

.cell>.el-select.el-select--small {
  display: none;
}

.el-tabs__nav-scroll {
  background: red;
}

.block {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
}

.senlists {
  width: 100%;
  padding: 18px 6px;
  padding-bottom: 0;
  border-radius: 6px;
  margin: 20px 0;
  //font-size: 20px;
}

.hd.card {
  padding: 15px;
  box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 5%);
  border-radius: 6px;
}

.card {
  border: none;
  margin-bottom: 20px;
}

.location {
  margin-bottom: 15px;
}

.card {
  background-color: #fff;
  background-clip: border-box;
  border: none;
  margin-bottom: 20px;
  box-shadow: 0 0 15px 0 rgb(34 41 47 / 5%);
  border-radius: 0.428rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  padding: 15px;
}

.text {
  margin-right: 20px;
}

.span.el-checkbox__inner {
  display: none;
}

.el-range-editor.el-input__inner {
  width: 240px;
}

input.el-input__inner {
  width: 220px;
}

/* .el-table--border .el-table__cell tr:nth-of-type(2) th:nth-of-type(1) .cell {
    display: none;
} */
.common-table-list-rectcle ::v-deep {
  .el-table .el-table__fixed-header-wrapper .el-table__header .is-group tr:nth-of-type(2) th:nth-of-type(1) .cell {
    display: none;
  }
}
</style>